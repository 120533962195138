html,
body {
  padding: 0;
  margin: 0;
  font-family: "Cabin Condensed", sans-serif;
  line-height: 1.2 !important;
}

* {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

p > a {
  color: #0099cc;
}
p > a:hover,
p > a:focus,
p > a:active {
  text-decoration: underline;
}

p ol,
p ul {
  padding-left: 1rem;
  padding-top: 0.5rem;
}
p ol li,
p ul li {
  margin-bottom: 1rem;
}

.sticky {
  top: 0;
  left: 0;
  right: 0;
  /* position sticky/fixed/absolute should have a z-index along with it */
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
}
/* icons in chakra have z-index up to 2, we must exceed so sticky header will go over it */

.product-thumbnails {
  text-align: center;
  margin-top: 1rem;
}
.product-thumbnails li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  width: "82px";
  height: "82px";
}

/*https://github.com/chakra-ui/chakra-ui/discussions/4616 */
.info-content {
  all: revert;
}

.info-content * {
  all: revert;
}

.highlight-on-hover:hover {
  background: rgba(0, 0, 0, 0.04);
  transition: background 250ms ease-out;
  color: #000;
}

/* makes sure the popover for shopping cart header does not move to the top left of the screen */
.profile-cart-header .css-r6z5ec {
  transform: translate3d(0px, 40px, 0px) !important;
}

.hr-text-container {
  max-width: 100%;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: #f2724e;
  text-align: center;
  height: 1.5em;
  font-weight: 700;
}

.hr-text:before {
  content: "";
  background: #f2724e;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 2px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: #f2724e;

  padding: 0 0.5em;
  line-height: 1.5em;
  background-color: #ffffff;
}

.mobile-promotion-section-drawer-content {
  bottom: 64px !important;
}

.force-quill-background span {
  background-color: transparent !important;
}

.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

.product-card-name-link {
  width: 100%;
  max-width: 100%;
  display: inline-block;
  font-weight: bold;
  color: #333333;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  /* padding-top: 4px;
  padding-bottom: 4px; */
}

.product-card-name-link:hover {
  text-decoration: underline;
}

.product-image-carousel .slick-next:before, .product-image-carousel .slick-prev:before {
  color: grey;
}

.carousel-main-image .slick-next {
  right: 5px;
}

.carousel-main-image .slick-prev {
  left: 5px;
  z-index: 1;
}
